<template>
  <div class="pa-10 proforma-invoice-edit">
    <title-page icon="ico-proforma-invoice">
      {{ $tc('proforma-invoice.proforma-invoice', 2) }}
    </title-page>


    <div v-if="proformaInvoice">

      <v-container>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <form-field :label="$tc('proforma-invoice.proforma-invoice-number')" v-model="proformaInvoice.proformaInvoiceReference" col-field="6" readonly />
               </v-col>
              <v-col>
                <form-field :label="$tc('status')" :value="$t('proforma-invoice.statues.' + proformaInvoice.status)" col-field="9" readonly />
               </v-col>
            </v-row>
            <v-row>
              <v-col>
                <form-field :label="$tc('created-at')" :value="formatDate(proformaInvoice.createdAt, $t('format_date'))" col-field="9" readonly />
                <form-field :label="$tc('period')" :value="$tc('period-from-to', 0, {start: formatUtcDate(proformaInvoice.startDate, $t('format_date')), end: formatUtcDate(proformaInvoice.endDate, $t('format_date')) })" col-field="9" readonly />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <headline>
              {{ $t('invoice.issuer') }}
            </headline>
            <v-row>
              <v-col>
                <form-field :label="$t('account-number')" v-model="proformaInvoice.issuerAccountNumber" readonly />
                <form-field :label="$t('name')" v-model="proformaInvoice.issuerName" readonly />
                <form-field :label="$t('address')" :value="addressToString(proformaInvoice.issuerAddress)" readonly />
                <form-field :label="$t('organization.registration-number')" v-model="proformaInvoice.issuerRegistrationNumber" readonly />
                <form-field v-if="!proformaInvoice.issuerIsVatExempt" :label="$t('vat-number')" v-model="proformaInvoice.issuerVatNumber" readonly />
                <form-field v-else :label="$t('is-vat-exempt')" v-model="proformaInvoice.recipientIsVatExempt" readonly type="switch" />
                <form-field :label="$t('email')" v-model="proformaInvoice.issuerEmail" readonly />
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <headline>
              {{ $t('invoice.recipient') }}
            </headline>
            <v-row>
              <v-col>
                <form-field :label="$t('account-number')" v-model="proformaInvoice.recipientAccountNumber" readonly />
                <form-field :label="$t('name')" v-model="proformaInvoice.recipientName" readonly />
                <form-field :label="$t('address')" :value="addressToString(proformaInvoice.recipientAddress)" readonly />
                <form-field :label="$t('organization.registration-number')" v-model="proformaInvoice.recipientRegistrationNumber" readonly />
                <form-field v-if="!proformaInvoice.recipientIsVatExempt" :label="$t('vat-number')" v-model="proformaInvoice.recipientVatNumber" readonly />
                <form-field v-else :label="$t('is-vat-exempt')" v-model="proformaInvoice.recipientIsVatExempt" readonly type="switch" />
                <form-field :label="$t('email')" v-model="proformaInvoice.recipientEmail" readonly />
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <v-row v-if="canEdit">
          <v-col class="text-right">
            <v-btn class="ml-3" small @click="updateBillingDetails" type="button">
              {{ $t('proforma-invoice.update-billing-details') }}
            </v-btn>
          </v-col>
        </v-row>




        <div v-for="item in proformaInvoice.items"  :key="item.id" class="mt-16">
          <div v-if="proformaInvoice.proformaInvoiceGroup" class="text-right caption">{{ $t('proforma-invoice.proforma-invoice-number', {number: item.proformaInvoiceReference}) }}</div>
          <v-divider class="mb-10"></v-divider>
          <proforma-invoice-edit-form :proforma-invoice="item" :can-edit="canEdit" countries-filter="countriesFilter"/>
          <v-btn small @click="detachProformaInvoice(item.id)" v-if="canEdit && proformaInvoice.proformaInvoiceGroup">
            Détacher la proforma de ce groupe
          </v-btn>
        </div>




        <v-divider class="my-10"></v-divider>

        <v-row>
          <v-col>
            <v-simple-table class="proforma-invoice-bases">
              <template v-slot:default>
                <thead>
                <tr>
                  <th width="100" class="text-right">{{ $t('proforma-invoice.base-ht') }}</th>
                  <th width="100" class="text-right">{{ $t('proforma-invoice.vat-rate') }}</th>
                  <th width="100" class="text-center">{{ $t('country') }}</th>
                  <th width="150" class="text-right">{{ $t('proforma-invoice.vat-amount') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(base, k) in bases" :key="k">
                  <td class="text-right">{{ !isNullOrUndefined(base.amount) ? numberFormat(base.amount) + '  €' : '-' }}</td>
                  <td class="text-right">{{ base.label ?? '-' }}</td>
                  <td class="text-center">{{ base.country ?? '-' }}</td>
                  <td class="text-right">{{ isNullOrUndefined(base.vatAmount) || base.selfLiquidation ? '-' : numberFormat(base.vatAmount) + ' €' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td width="150" class="text-right">Montant HT</td>
                  <td width="150" class="text-right">{{ numberFormat(proformaInvoice.netTotal) }} €</td>
                </tr>
                <tr>
                  <td class="text-right">TVA</td>
                  <td class="text-right">{{ numberFormat(proformaInvoice.vatTotal) }} €</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">Montant TTC</td>
                  <td class="text-right font-weight-bold">{{ numberFormat(proformaInvoice.finalTotal) }} €</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>



        <template v-if="proformaInvoice.proformaInvoiceGroup && canEdit">
          <v-divider class="my-10"></v-divider>
          <v-row>
            <v-col cols="auto" class="d-flex align-center">
              Attacher un proforma à ce groupe
            </v-col>
            <v-col>
              <v-autocomplete
                  v-model="proformaInvoiceToAttach"
                  :items="proformaInvoices"
                  :search-input.sync="searchProformaInvoice"
                  :loading="proformaInvoicesLoading"
                  placeholder="Sélectionner le proforma à attacher"
                  clearable
                  item-value="id"
                  hide-no-data
                  hide-details
                  no-filter
              >
                <template #selection="{item}">
                  <v-row class="mb-2">
                    <v-col class="text-left">
                      <div class="d-flex justify-lg-space-between">
                        <b>{{ item.proformaInvoiceReference }}</b>
                        <span class="font-weight-thin">{{ item.recipientName }}</span>
                      </div>
                      <div class="font-weight-thin">
                        {{ item.startDate|formatDate($t('format_date')) }} - {{ item.endDate|formatDate($t('format_date')) }} : {{ item.netTotal }} € ({{ item.proformaInvoiceContentType }})
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template #item="{item}">
                  <v-row class="mb-2">
                    <v-col class="text-left">
                      <div class="d-flex justify-lg-space-between">
                        <b>{{ item.proformaInvoiceReference }}</b>
                        <span class="font-weight-thin">{{ item.recipientName }}</span>
                      </div>
                      <div class="font-weight-thin">
                        {{ item.startDate|formatDate($t('format_date')) }} - {{ item.endDate|formatDate($t('format_date')) }} : {{ item.netTotal }} € ({{ item.proformaInvoiceContentType }})
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <v-btn small @click="attachProformaInvoice" :disabled="!proformaInvoiceToAttach">
                Attacher
              </v-btn>
            </v-col>
          </v-row>
        </template>



        <v-divider class="my-10"></v-divider>
        <v-row>
          <v-col class="text-right">
            <v-btn small @click="preview">Previsualiser le proforma</v-btn>
            <v-btn small class="ml-6" @click="downloadPdf">Télécharger le PDF</v-btn>
            <v-btn small class="ml-6" @click="downloadAttachment">Télécharger les pièces-jointes</v-btn>
            </v-col>
        </v-row>


        <template v-if="proformaInvoice.reviewedBy">
          <v-divider class="my-10"></v-divider>
          <v-row>
            <v-col class="font-weight-thin">
              La facture proforma N° <b>{{ proformaInvoice.proformaInvoiceReference }}</b> a été vérifié le {{ proformaInvoice.reviewedAt|formatDate($t('format_date')) }} par <b>{{ proformaInvoice.reviewedBy.firstname }} {{ proformaInvoice.reviewedBy.lastname }}</b>
            </v-col>
          </v-row>
        </template>

        <template v-if="proformaInvoice.status === 'invoiced' && proformaInvoice.invoice">
          <v-divider class="my-10"></v-divider>
          <v-row>
            <v-col class="font-weight-thin">
              La facture <b>{{ proformaInvoice.invoice.invoiceReference }}</b> a été créée le {{ proformaInvoice.invoice.createdAt|formatDate($t('format_date')) }} par <b>{{ proformaInvoice.invoice.createdBy.firstname }} {{ proformaInvoice.invoice.createdBy.lastname }}</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn :to="{name: 'proforma-invoices'}" exact>Fermer</v-btn>
              <v-btn color="primary" class="ml-6"  @click="openInvoice">Voir la facture</v-btn>
            </v-col>
          </v-row>
        </template>




      </v-container>







      <template  v-if="proformaInvoice.status === 'pending' || proformaInvoice.status === 'rejected' || proformaInvoice.status === 'validated' || proformaInvoice.status === 'under-review'">
        <v-divider class="my-10"></v-divider>

        <v-row v-if="['validated'].includes(proformaInvoice.status)">
          <v-col class="text-center">
            Souhaitez vous valider ou rejeter la facture proforma ? cela créera la facture definitive
          </v-col>
        </v-row>


        <v-row>
          <v-col class="text-center">
            <v-btn :to="{name: 'proforma-invoices'}" exact>Fermer</v-btn>
            <v-btn v-if="proformaInvoice.status !== 'rejected' && proformaInvoice.status !== 'invoiced'" class="error  ml-6" @click="reject">Rejeter</v-btn>

            <v-btn v-if="proformaInvoice.status === 'pending'" class="orange white--text ml-6" @click="underReview">Editer la facture</v-btn>
            <v-btn v-if="proformaInvoice.status === 'under-review'" class="orange white--text ml-6" @click="restore">Remettre la facture en attente ?</v-btn>

            <v-btn v-if="proformaInvoice.status === 'rejected'" class="success ml-6" @click="restore">Récupérer le proforma</v-btn>
            <v-btn v-if="proformaInvoice.status === 'validated'" class="orange white--text ml-6" @click="restore">Annuler la validation</v-btn>
            <v-btn v-if="proformaInvoice.status === 'pending' || proformaInvoice.status === 'under-review'" class="success ml-6" @click="validate">Valider le proforma</v-btn>
            <v-btn v-if="proformaInvoice.status === 'validated'" class="success ml-6" @click="convertToInvoice">Créer la facture</v-btn>
          </v-col>
        </v-row>
      </template>

      <v-row class="mt-10"  v-if="proformaInvoice.proformaInvoiceGroup && proformaInvoice.status !== 'invoiced'">
        <v-col class="text-center">
          <v-btn color="error" outlined  dark @click="deleteGroup">Dégrouper les proformas ?</v-btn>
        </v-col>
      </v-row>


    </div>


    <proforma-invoice-show-modal ref="proformaInvoiceModal" />
    <invoice-show-modal ref="invoiceModal" from-proforma-invoice />



  </div>
</template>

<script>
import ProformaInvoiceRepository from "@repository/ProformaInvoiceRepository";
import VatRateRepository from "@repository/VatRateRepository";
import ProformaInvoiceEditForm from "@pages/ProformaInvoice/elements/ProformaInvoiceEditForm.vue";
import ProformaInvoiceShowModal from "@pages/ProformaInvoice/modals/ProformaInvoiceShowModal.vue";
import InvoiceShowModal from "@pages/Invoice/modals/InvoiceShowModal.vue";


export default {
  components: {
    ProformaInvoiceShowModal,
    InvoiceShowModal,
    ProformaInvoiceEditForm,
  },
  data() {
    return {
      proformaInvoice: null,
      proformaInvoices: [],
      searchProformaInvoice: null,
      proformaInvoiceToAttach: null,
      proformaInvoicesLoading: false,
    }
  },

  mounted() {
    this.loadData().then(data => {
      if(data.proformaInvoiceGroup) {
        this.loadProformaInvoices()
      }
    })
    this.loadVatRate()
  },

  watch: {
    searchProformaInvoice() {
      this.loadProformaInvoices()
    }
  },

  computed: {

    id() {
      return this.$route.params.id
    },

    proformaInvoiceLinesCleaned() {
      const finalLines = []
      for(let item of this.proformaInvoice.items) {
        const lines = item.lines
        let lineById = {}
        for(let line of lines) {
          lineById[line.id] = line
        }
        for(let line of lines) {
          if(line.corrective?.correctiveType !== 'delete') {
            finalLines.push({...line, ...line.corrective})
          } else {
            finalLines.push(line)
          }
        }
      }
      return finalLines
    },

    canEdit() {
      return this.proformaInvoice.status === 'under-review'
    },

    bases() {
      const bases = {}

      for(let item of this.proformaInvoiceLinesCleaned ) {
        if(item.isDisbursement || item.corrective?.correctiveType === 'delete' || item.corrective?.isDisbursement) {
          continue
        }

        let b = item.vatRate
        if(item.selfLiquidation) {
          b = 'auto'
        }
        b += '_' + item.country

        if(!bases[b]) {
          bases[b] = {
            label: item.selfLiquidation ? this.$t('proforma-invoice.self-liquidation') : item.vatRate + '%',
            country: item.country,
            amount: 0,
            vatAmount: 0,
            selfLiquidation: item.selfLiquidation,
          }
        }
        bases[b].amount += item.netTotal
        bases[b].vatAmount += item.valueAddedTax
      }

      if(Object.keys(bases).length === 0) {
        return {
          "empty": {

          }
        }
      }

      return bases
    },
  },

  methods: {

    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        ProformaInvoiceRepository.edit(this.id).then(response => {
          this.proformaInvoice = response
          this.showLoading(false)
          resolve(response)
        }).catch(err => {
          this.notifyError(err)
          this.showLoading(false)
          reject(err)
        })
      })
    },

    loadProformaInvoices() {
      return new Promise((resolve, reject) => {

        let data = {
          searchText: this.searchProformaInvoice,
          onlyEditable: true,
        }
        if(this.proformaInvoice.organizationId) {
          data.organizationId = this.proformaInvoice.organizationId
        }

        if(this.proformaInvoice.memberId) {
          data.memberId = this.proformaInvoice.memberId
        }

        if(this.proformaInvoice.customerId) {
          data.customerId = this.proformaInvoice.customerId
        }

        if(this.proformaInvoice.partnerOperatorGroupId) {
          data.partnerOperatorGroupId = this.proformaInvoice.partnerOperatorGroupId
        } else if(this.proformaInvoice.agreementId) {
          data.agreementId = this.proformaInvoice.agreementId
        }

        data.limit = 40
        data.sortBy = "startDate"
        data.sortOrder = 'desc'

        this.proformaInvoicesLoading = true
        ProformaInvoiceRepository.search(data).then(response => {
          this.proformaInvoices = response.items
          this.proformaInvoicesLoading = false
          resolve(response)
        }).catch(err => {
          this.notifyError(err)
          this.proformaInvoicesLoading = false
          reject(err)
        })
      })
    },

    loadVatRate() {
      VatRateRepository.search({limit: 1000}).then(response => {
        this.countriesFilter = response.items.map(item => item.country)
      }).catch(err => {
        this.notifyError(err)
      })
    },

    attachProformaInvoice() {
      this.showLoading(true)
      ProformaInvoiceRepository.attachProformaInvoiceToGroup(this.id, this.proformaInvoiceToAttach).then(url => {
        this.loadData()
        this.loadProformaInvoices()
        this.proformaInvoiceToAttach = null
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    detachProformaInvoice(itemId) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          ProformaInvoiceRepository.detachProformaInvoiceFromGroup(itemId).then(result => {
            if (result.status === 'updated') {
              this.loadData()
            } else {
              this.showLoading(false)
              this.$router.replace({name: 'proforma-invoices'})
            }
          }).catch(error => {
            this.notifyError(error)
            this.showLoading(false)
          })
        }
      })
    },


    updateBillingDetails() {
      this.showLoading(true)
      ProformaInvoiceRepository.updateBillingDetails(this.id).then(url => {
        this.loadData()
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    preview() {
      this.$refs.proformaInvoiceModal.open(this.proformaInvoice.id)
    },

    openInvoice() {
      this.$refs.invoiceModal.open(this.proformaInvoice.invoiceId)
    },



    downloadPdf() {
      this.showLoading(true)
      ProformaInvoiceRepository.pdf(this.proformaInvoice.id).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    downloadAttachment() {
      this.showLoading(true)
      ProformaInvoiceRepository.downloadAttachment(this.proformaInvoice.id).then(() => {
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    reject() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          ProformaInvoiceRepository.reject(this.id, "").then(url => {
            this.loadData()
          }).catch(error => {
            this.notifyError(error)
            this.showLoading(false)
          })
        }
      })
    },

    validate() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          ProformaInvoiceRepository.validate(this.id).then(url => {
            this.loadData()
          }).catch(error => {
            this.notifyError(error)
            this.showLoading(false)
          })
        }
      })
    },

    restore() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          ProformaInvoiceRepository.restore(this.id).then(url => {
            this.loadData()
          }).catch(error => {
            this.notifyError(error)
            this.showLoading(false)
          })
        }
      })
    },

    underReview() {
      this.showLoading(true)
      ProformaInvoiceRepository.underReview(this.id).then(url => {
        this.loadData()
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    deleteGroup() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          ProformaInvoiceRepository.deleteGroup(this.id).then(url => {
            this.$router.replace({name: 'proforma-invoices'})
          }).catch(error => {
            this.notifyError(error)
            this.showLoading(false)
          })
        }
      })
    },


    convertToInvoice() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          ProformaInvoiceRepository.convertToInvoice(this.id, "").then(url => {
            this.loadData()
          }).catch(error => {
            this.notifyError(error)
            this.showLoading(false)
          })

        }
      })
    },

  },

}
</script>

<style lang="scss">
.proforma-invoice-edit {



  //.v-select__selections{
  //  flex-direction: column;
  //  align-items: start;
  //  .mb-2 {
  //    margin-bottom: 0 !important;
  //  }
  //}
  .v-data-table {
    tr {
      &:hover {
        background-color: transparent !important;
      }
      &.deleted {
        td:not(.btns) {
          text-decoration: line-through;
          color: #ccc;
        }
      }
      th {
        border-top: 1px solid #e0e0e0 !important;
        border-left: 1px solid #e0e0e0 !important;
        &:last-child {
          border-right: 1px solid #e0e0e0 !important;
        }
      }
      td {
        border-top: 1px solid #e0e0e0 !important;
        border-left: 1px solid #e0e0e0 !important;
        height: 30px !important;
        &.proforma-invoice-line-type {
          text-align: center;
          background: #eee;
        }
        &.no-border {
          border-left: 0 !important;
        }
        &:last-child {
          border-right: 1px solid #e0e0e0 !important;
        }
      }
      &:last-child {
        td:not(.no-border) {
          border-bottom: 1px solid #e0e0e0 !important;
        }
      }
    }
  }
}
</style>
